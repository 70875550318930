@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    .marquee-container {
        width: 100%;
        overflow: hidden;
    }

    .marquee-content {
        display: inline-block;
        white-space: nowrap;
        animation: marquee 180s linear infinite;
    }

    .plur-box {
        display: inline-block;
        padding: 0.25rem 0.5rem;
        margin: 0 0.25rem;
        border-radius: 0.25rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .plur-box-peace {
        background-color: rgba(147, 51, 234, 0.7);
        /* Purple */
    }

    .plur-box-love {
        background-color: rgba(236, 72, 153, 0.7);
        /* Pink */
    }

    .plur-box-unity {
        background-color: rgba(249, 115, 22, 0.7);
        /* Orange */
    }

    .plur-box-respect {
        background-color: rgba(59, 130, 246, 0.7);
        /* Blue */
    }
}